
<div class="input-group" style="padding-bottom: 30px;" >
  <form (ngSubmit)="signupMail()">

  
  <input type="email" class="input" id="Email" name="Email" placeholder="Insert membre gmail" autocomplete="off" name="recipient" [(ngModel)]="recipient" [ngModelOptions]="{standalone: true}" required pattern="[a-zA-Z0-9._\-]+@[a-zA-Z0-9.\-]+\.[a-zA-Z]{2,4}">
  <input class="button--submit" value="Invite new membre" type="submit">
  <!-- <div class="alert alert-success" *ngIf="sent"> INVITATION SENT SUCCESSFULLY</div>
  <div class="alert alert-warning" *ngIf="Notsent"> ERROR WHILE SENDING THE REQUEST</div> -->
</form></div>

<div class="kwart" id="kwart" >

<div class="card"   *ngFor="let m of members" >
  <div class="content">
    <p class="heading" style="text-align: center;"> {{m.username}}
    </p><p class="para">
    <p>Email :  {{m.email}}</p>  
    <p>Telephone : {{m.telephone}}</p>  
    <p>Education : {{m.niveau}}</p>  
    <p>Poste : {{m.poste}}</p>  
      
    
    <button class="btn">Delete</button>
  </div>
</div>
</div>

