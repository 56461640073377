import { Component, OnInit } from '@angular/core';
import { User } from '../models/User';
import { TokenStorageService } from '../services/token-storage.service';
import { Router,ActivatedRoute, Params } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { AuthService } from '../services/auth.service';
import { first } from 'rxjs';
import { Project } from '../models/Project';

@Component({
  selector: 'app-add-member-in',
  templateUrl: './add-member-in.component.html',
  styleUrls: ['./add-member-in.component.css']
})
export class AddMemberINComponent implements OnInit {
  tokenStorage: TokenStorageService;
   user!:User
  user_id!:number;
  username!: string;
  password!: string;
  email!: string;
  poste!:string;
  niveau!:string;
  Rpassword!: string;
  telephone!: number;
  role:any =["user"];
  sm=false;
  nosm=false;
  invalidLogin = false;
  loginSuccess = false;
  isLoggedIn = false;
  project_id!:number;
  

  constructor(private router: Router,
    private route : ActivatedRoute,
    private http: HttpClient,
    private tokenStorageService: TokenStorageService,
    private authService: AuthService) { this.tokenStorage = tokenStorageService;}

  ngOnInit(): void {
    // this.project_id=Number(sessionStorage.getItem("project_id"));
    this.route.queryParams.subscribe(
      params => {
        this.project_id =params['project_id']
        console.log(this.project_id); 
       }
    )
  }



  Membrsignup(){
    
   
    this.authService.registerMember(this.username, this.telephone,  this.email, this.password, this.role, this.niveau,this.project_id,this.poste)
    .pipe(first())
    .subscribe(
      (data) => {
        console.log(data)
        this.sm=true;
        this.router.navigate(['/Auth']);
              },
      (error) => {
        this.nosm=true;
        console.log('erreur signup',error)
      }
    );
  }

}
