<div *ngIf="task"><h2 style="text-align: center;">  Task details  {{task.title}} | {{task.etat}} </h2></div>

<div class="row">

  <!-- ////////////////// COLUMN 1 //////////////////////// -->
<div class="column" >
  <form>
    <div class="md-form mb-5" >
                                    
        <div class="md-form mb-5" >
        <label data-error="wrong" data-success="right" for="orangeForm-name">Title</label>
        <input  style="text-align: center;" type="text" id="orangeForm-email" class="form-control validate" name="title" [(ngModel)]="task.title" [ngModelOptions]="{standalone: true}" >
        </div>

      <div class="md-form mb-5">
        <label data-error="wrong" data-success="right" for="orangeForm-name">Desciption</label>
        <textarea rows="4" style="text-align: center;"   id="orangeForm-pass" class="form-control validate" name="description" [(ngModel)]="task.description" [ngModelOptions]="{standalone: true}" ></textarea>
        </div>

        <div class="md-form mb-5">
            <label data-error="wrong" data-success="right" for="orangeForm-name">Starte date</label>
            <input type="date" style="text-align: center;"  id="orangeForm-pass" class="form-control validate" name="start_date" [(ngModel)]="task.start_date" [ngModelOptions]="{standalone: true}" >
            </div>

            <div class="md-form mb-5">
                <label data-error="wrong" data-success="right" for="orangeForm-name">Deadline</label>
                <input type="date" style="text-align: center;"  id="orangeForm-pass" class="form-control validate" name="deadline" [(ngModel)]="task.deadline" [ngModelOptions]="{standalone: true}" >
                </div>


            
                


                <div class="md-form mb-5">
                 <div> <label>Status : {{task.etat}}</label>   </div>
                <select  #select (change)="onChange(select.value)" [(ngModel)]="task.etat" [ngModelOptions]="{standalone: true}"> 
                  <option  class="dropdown-item" value="TODO" >TODO</option>
                  <option  class="dropdown-item" value="DOING" >DOING</option>
                  <option  class="dropdown-item" value="TEST" >TEST</option>
                  <option  class="dropdown-item" value="DONE" >DONE</option>

                </select> </div>

      
       
    <div class="modal-footer d-flex justify-content-center" *ngIf="isAdmin">
      <button   class="btn btn-warning" type="submit" (click)="updateTask(task.task_id)">Update</button>
      <button class="btn btn-danger" type="submit" (click)="deleteTask(task.task_id)" >Delete</button>
    
    </div></div>
  </form>
</div> 

  <!-- ////////////////// COLUMN 1 //////////////////////// -->
<div class="column" >
         <div>

<h3>Assign member</h3>
<select #cricket (change)="update($event)">
    <option value="default">----</option>
    <option *ngFor="let m of members" [value]="m">
        {{m.username}}
    </option>
</select>

         </div>
 
               

<div classe="karta">
                 <div class="card" *ngFor="let u of users">
                  <div class="card-border-top">
                  </div>
                  
                  <span> {{u.post}}</span>
                  <h1 class="job"> {{u.username}}</h1>
                  <button> Unassign
                  </button>
                </div>   
              </div>         

                    


















</div>


