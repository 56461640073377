import { Component, OnInit } from '@angular/core';
import { UserService } from './services/user.service';
import { TokenStorageService } from './services/token-storage.service';
import { Router } from '@angular/router';
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css'],
 
})
export class AppComponent {
  title = 'PLM';
  
  
  constructor(private userService: UserService ,  private router: Router, private token: TokenStorageService  ) { }

  ngOnInit(): void { 

      }
    };




  
