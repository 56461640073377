import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { UserService } from '../services/user.service';
import { TokenStorageService } from '../services/token-storage.service';
import { HttpClient } from '@angular/common/http';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.css']
})
export class FooterComponent implements OnInit {
   User: any;
  role:any;
  id:any;
  Username!:string;
  isAdmin=false;
  isUser=false;
  showMe=false;

  constructor( private userService: UserService ,  private router: Router, private token: TokenStorageService , private http: HttpClient) { }

  ngOnInit(): void {
   
    
    this.User = this.token.getUser();
    //  this.token.getUser();
    // this.userService.getAdminBoard()
    // .subscribe({
    //   next: (data:any) => {
    //     console.log(this.User)
    this.role= this.token.getUser().roles;
    this.Username=this.token.getUser().username;
    this.id=this.token.getUser().id
       
      // },
      // error: (err:any) => {
      //   TokenStorageService.signOut();
      //   this.router.navigate(['/Auth']);



      if ((this.role=["ROLE_ADMIN"]) &&( this.id != null)){
        
        this.isAdmin=true;
        this.showMe=true;
      }
      else if ((this.role=["ROLE_USER"]) &&( this.id != null)){
       
        this.isUser=true;
        this.showMe=true;
      
    }
    //  else {this.router.navigate(['/Auth']); }
      
  }

  logout(){
    this.token.clearToken()
  }

}
