import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { Project } from '../models/Project';
import { TokenStorageService } from './token-storage.service';
import { HttpParams } from '@angular/common/http';
import { Sprint } from '../models/Sprint';

//const baseUrl = 'http://localhost:8080/api/v1/project';
//const SprintUrl = 'http://localhost:8080/api/v1/sprint';

const baseUrl = 'https://todolist.back.dpc.com.tn/api-docs/api/v1/project';
const SprintUrl = 'https://todolist.back.dpc.com.tn/api-docs/api/v1/sprint';

@Injectable({
  providedIn: 'root'
})
export class ProjectService {
  currentProject :any;
   project_id!: any; 
  title!: string;
  description!: string;
  sprint!:[];
  creator_id!: string;

  constructor(private http: HttpClient, private token : TokenStorageService) { }
  username=this.token.getUser().username;

  //GET BY CREATOR ID
  getAll(): Observable<Project[]>{
       const url = 'http://localhost:8080/api/v1/project/getProjects';
    let queryParams = new HttpParams();
    queryParams = queryParams.append("username",this.username);
    return this.http.get<Project[]>(url,{params:queryParams})

  }

  getAllsprints(project_id:any){
    const token= localStorage.getItem("auth-token");
    const headers = new HttpHeaders().set("Authorization","Bearer"+token);

    return this.http.get(SprintUrl + `/getByProjectId/${project_id}`,{headers: headers});
  
  }

  get(project_id:any) {
    const token= localStorage.getItem("auth-token");
    const headers = new HttpHeaders().set("Authorization","Bearer"+token);
  
    return this.http.get(baseUrl + `/get/${project_id}`,{headers: headers});
  }

 

  updatepProject(project_id:number,title:string,description:string){
    const token= localStorage.getItem("auth-token");
    const headers = new HttpHeaders().set("Authorization","Bearer"+token);
    
    return this.http.put(baseUrl + `/UptProject/${project_id}`,{"project_id":project_id,"title": title,"description":description}, {responseType: 'text'});
  }

  deleteProject(project_id:number){
    const token= localStorage.getItem("auth-token");
    const headers = new HttpHeaders().set("Authorization","Bearer"+token);
    return this.http.delete(baseUrl+ `/DelProject/${project_id}`, {responseType: 'text'})
  }

createProject(title:string,description:string){
  const token= localStorage.getItem("auth-token");
    const headers = new HttpHeaders().set("Authorization","Bearer"+token);
    return this.http.post(baseUrl+ `/NewProject`,{"title": title,"description":description}, {responseType: 'text'} )
}

}
