import { HttpClient , HttpHeaders} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { TokenStorageService } from './token-storage.service';


//const baseUrl = 'http://localhost:8080/api/v1/sprint';

const baseUrl = 'https://todolist.back.dpc.com.tn/api-docs/api/v1/sprint';
@Injectable({
  providedIn: 'root'
})
export class SprintService {
  project_id!: number
  
  constructor(private http: HttpClient, private token : TokenStorageService) { }

  getSprintByid(sprint_id:any){
    const token= localStorage.getItem("auth-token");
    const headers = new HttpHeaders().set("Authorization","Bearer"+token);
    return this.http.get(baseUrl + `/get/${sprint_id}`,{headers: headers});

  }

  createSprint(Stitle:string,Sdescription:string,project_id:number){
  const token= localStorage.getItem("auth-token");
  const headers = new HttpHeaders().set("Authorization","Bearer"+token);
  return this.http.post(baseUrl+ `/NewSprint/${project_id}`,{"title": Stitle,"description":Sdescription}, {responseType: 'text'})
}

 updateSprint(project_id:number,sprint_id:any,Stitle:string,Sdescription:string){   
  const token= localStorage.getItem("auth-token");
  const headers = new HttpHeaders().set("Authorization","Bearer"+token);
  return this.http.put(baseUrl+`/EditSprint/${project_id}`,{"sprint_id":sprint_id,"title": Stitle,"description":Sdescription}, {responseType: 'text'})
}

deleteSprint(sprint_id:any){
  const token= localStorage.getItem("auth-token");
  const headers = new HttpHeaders().set("Authorization","Bearer"+token);
  return this.http.delete(baseUrl+`/DelSprint/${sprint_id}`, {responseType: 'text'})
}

}
