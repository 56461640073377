


<div class="contain" >
  <ul class="nav">
  <li class="nav-item" *ngIf="isAdmin" style="margin:auto ;"> 
      &nbsp; &nbsp;<a href="" class="btn btn-warning" data-toggle="modal" data-target="#modalNewProject" ><h4 style="padding-top: 10px;"> <b>Create Project</b></h4> </a>
         </li></ul> <br>
    <div class="align">
<div class="card" *ngFor="let p of projectlist " >
  
  <div class="align">
      <span class="red"></span><span class="yellow"></span><span class="green"></span>
  </div>
  
   <a [routerLink]="['/Projectdetails', p.project_id]" >
    <h1>{{ p.title }}</h1>
  </a>
  <p>
    <label> <b>Project ID : {{p.project_id}}</b> </label> <br>
     <span> 
      <label> <b>Description :</b> </label> <br>
       {{p.description}}     
    </span>
  </p>

</div>
</div>
</div>

            <!-- NEW PROJECT MODAL -->
            <div class="modal fade" id="modalNewProject" tabindex="-1" role="dialog" aria-labelledby="myModalLabel"
  aria-hidden="true">
  <div class="modal-dialog" role="document">
    <div class="modal-content">
      <div class="modal-header text-center">
        <h4 class="modal-title w-100 font-weight-bold">Create a new project</h4>
        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body mx-3">
        <div class="md-form mb-5">
          <form name="createProject" > 
                    &nbsp;
                    <label data-error="wrong" data-success="right" for="orangeForm-name">Title</label>
                    <input type="text" id="orangeForm-name" class="form-control validate" name="title" [(ngModel)]="title" [ngModelOptions]="{standalone: true}">

                    <div class="md-form mb-5">
                    &nbsp;
                    <label data-error="wrong" data-success="right" for="orangeForm-name">description</label>
                    <textarea style="text-align: center;" rows="9" type="text" id="orangeForm-email" class="form-control validate" name="description" [(ngModel)]="description" [ngModelOptions]="{standalone: true}"></textarea>
                    </div>
                    <div class="modal-footer d-flex justify-content-center">
                      <button class="btn btn-primary" (click)="create()" >Create</button>
                    
                    </div></form></div>

                 

                 




