import { Component, OnInit } from '@angular/core';
import { UserService } from '../services/user.service';
import { TokenStorageService } from '../services/token-storage.service';
import { Router } from '@angular/router';
import { HttpClient } from '@angular/common/http';


@Component({
  selector: 'app-admin-dash',
  templateUrl: './admin-dash.component.html',
  styleUrls: ['./admin-dash.component.css']
})
export class AdminDashComponent implements OnInit {
 
  User: any;
  role:any;
  username:any;
  profile= false;
  


  constructor(private userService: UserService ,  private router: Router, private token: TokenStorageService , private http: HttpClient ) { 
    
  }

  ngOnInit(): void { 
   
    this.User = this.token.getUser();
    this.role= this.User.role;
    this.userService.getAdminBoard().subscribe({
      next: data => {
       console.log("Welcome Admin ");
      
       console.log(this.User.username)
       console.log(this.User.roles);
       window.location.reload
      },

      

      error: err => {
        // this.token.clearToken();
        // this.router.navigate(['/Auth']);
       
      }
    });

    this.userService.getUserBoard().subscribe({
      next: data => {
       console.log("Welcome Membre ");
      
       console.log(this.User.username)
       console.log(this.User.roles);
       window.location.reload
      },

      

      error: err => {
        // this.token.clearToken();
        // this.router.navigate(['/Auth']);
       
      }
    });

  }


  getProfile(){
    this.profile=true;
  }
  logout(){
    window.sessionStorage.clear();
    window.localStorage.clear();
    this.router.navigate(['/Auth']);
  }
}
