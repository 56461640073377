import { Injectable } from '@angular/core';
import { HttpClient , HttpHeaders} from '@angular/common/http';
import { TokenStorageService } from './token-storage.service';
import { Observable } from 'rxjs';
//const baseUrl ='http://localhost:8080/api/v1/status';
//const taskURL ='http://localhost:8080/api/v1/task'
//const UserURL ='http://localhost:8080/api/v1/user';

const baseUrl ='https://todolist.back.dpc.com.tn/api-docs/api/v1/status';
const taskURL ='https://todolist.back.dpc.com.tn/api-docs/api/v1/task' ;
const UserURL ='https://todolist.back.dpc.com.tn/api-docs/api/v1/user';
@Injectable({
  providedIn: 'root'
})
export class StatusServiceService {




  constructor(private http: HttpClient, private token : TokenStorageService) { }

  getSprintByid(title:string){
    const token= localStorage.getItem("auth-token");
    const headers = new HttpHeaders().set("Authorization","Bearer"+token);
    return this.http.get(baseUrl + `/getByTitle/${title}`,{headers: headers});

  }

  FTSS(sprint:any,etat:string){
    const token= localStorage.getItem("auth-token");
    const headers = new HttpHeaders().set("Authorization","Bearer"+token);
    return this.http.get(taskURL + `/findByEtat/${sprint}/${etat}`,{headers: headers});
  }

  createTask(sprint_id:number,title:string){
    const token= localStorage.getItem("auth-token");
      const headers = new HttpHeaders().set("Authorization","Bearer"+token);
      return this.http.post(taskURL+ `/NewTask/${sprint_id}`,{"title": title}, {responseType: 'text'} )
}

getById(task_id:number): Observable<any> {
  const token= localStorage.getItem("auth-token");
  const headers = new HttpHeaders().set("Authorization","Bearer"+token);
  return this.http.get(taskURL+ `/get/${task_id}`,{headers: headers});
}

UpdateTask(task_id:number,title:string,description:string,start_date:Date,deadline:Date,etat:string): Observable<any> {
  const token= localStorage.getItem("auth-token");
  const headers = new HttpHeaders().set("Authorization","Bearer"+token);
return this.http.put(taskURL+ `/EditTask/${task_id}`,{"task_id":task_id,"title":title,"description":description,"start_date":start_date,"deadline":deadline,"etat":etat},{responseType : 'text'});
}
DeleteTask (task_id:number)  :Observable<any>{
  const token= localStorage.getItem("auth-token");
  const headers = new HttpHeaders().set("Authorization","Bearer"+token);
  return  this.http.delete(taskURL+ `/DelTask/${task_id}`,{responseType : 'text'});
}
GetMember(task_id:number) :Observable<any>{
  const token= localStorage.getItem("auth-token");
  const headers = new HttpHeaders().set("Authorization","Bearer"+token);
  return this.http.get(UserURL+`/findByTask/${task_id}`,{headers: headers})
}



}
