import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { TokenStorageService } from '../services/token-storage.service';
import { User } from '../models/User';
import { UserCrudService } from '../services/user-crud.service';
import { first } from 'rxjs';


@Component({
  selector: 'app-profile',
  templateUrl: './profile.component.html',
  styleUrls: ['./profile.component.css']
})
export class ProfileComponent implements OnInit {
  
  tokenStorage!: TokenStorageService;
  User:any;
 user_id!:bigint;
 username!: string;
 password!: string;
 email!: string;
 telephone!: number;
 isLoggedIn = false;
 role=[""];

  constructor(private router: Router,
    private http: HttpClient,private userCrudService:UserCrudService) { }

  ngOnInit(): void {
    if (this.tokenStorage.getToken()) {
      this.isLoggedIn = true;
      this.role = this.tokenStorage.getUser().roles;
      this.user_id = this.tokenStorage.getUser().user_id;
    }
  }

  GetAllUser(){
    this.userCrudService.getAll()
    .pipe(first()).subscribe(
      (data)=>{console.log(data)}
      ,(error)=>{console.log(error)}
    )
  }
 

}
