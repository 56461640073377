import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
const TOKEN_KEY = 'auth-token';
const USER_KEY = 'auth-user';

@Injectable({
  providedIn: 'root'
})
export class TokenStorageService {

  static signOut(): import("rxjs").Observable<any> {
    throw new Error('----Method not implemented.');}

  constructor(private router:Router) { }
  signOut(): void {
    window.sessionStorage.clear();
    window.localStorage.clear();
    
   console.log('signed out')
    
  }

  public saveToken(token: string): void {
    window.sessionStorage.removeItem(TOKEN_KEY);
    window.sessionStorage.setItem(TOKEN_KEY, token);
  }

  public getToken(): string | null {
    return window.sessionStorage.getItem(TOKEN_KEY);
  }

  public saveUser(user: any): void {
    window.sessionStorage.removeItem(USER_KEY);
    window.sessionStorage.setItem(USER_KEY, JSON.stringify(user));
  }

  public getUser(): any {
    const user = window.sessionStorage.getItem(USER_KEY);
    if (user) {
      return JSON.parse(user);
    }

    return {};
  }
  public clearToken() : void{
    sessionStorage.removeItem('auth-token');
    sessionStorage.removeItem('auth-user');

    this.router.navigate(['/Auth'])
  }

}
