import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { SprintService } from '../services/sprint.service';
import { MatTableModule } from '@angular/material/table';
import { TokenStorageService } from '../services/token-storage.service';
import { User } from '../models/User';


import { StatusServiceService } from '../services/status-service.service';

@Component({
  selector: 'app-sprint',
  templateUrl: './sprint.component.html',
  styleUrls: ['./sprint.component.css'],
})
export class SprintComponent implements OnInit {
  currentSprint!: any;
  sprint_id!:number;
  title!:string;
  description!:string;
  user!: User
  id!:any;
  role:any;
  username!:string
  isAdmin=false;
  project_id!:number | any;
  dummy!:string|any;
  DOING="DOING";
  TODO="TODO";
  DONE="DONE";
  TEST="TEST";
  
  TodoTasks!: any;
  DoingTasks!:any;
  TestTasks!:any;
  DoneTasks!:any;
  tasks!:[];

  Ttitle!:string;

  constructor( private http:HttpClient ,private status:StatusServiceService, private sprintservice :SprintService, private router : Router,  public route: ActivatedRoute , private token: TokenStorageService ) { }

  ngOnInit(): void {
  
    this.init();


     
  }

  init(){
this.dummy=sessionStorage.getItem("project_id")


    this.project_id= JSON.parse(this.dummy)
    console.log("project_id +> ",this.project_id)
    this.user = this.token.getUser();
    this.username=this.token.getUser().username;
    this.role= this.token.getUser().roles;
    this.id=this.token.getUser().id
    if ((this.role=["ROLE_ADMIN"]) &&( this.id != null)){
      this.isAdmin=true;}
      

    const  sprint_id=this.route.params.subscribe(params => {
     this.sprint_id=params['sprint_id']
     sessionStorage.setItem("sprint_id",this.sprint_id.toString());

     this.getSprint(params['sprint_id'])
     this.TodoFTSS( params['sprint_id'],this.TODO)
     this.DoingFTSS( params['sprint_id'],this.DOING)
     this.TestFTSS( params['sprint_id'],this.TEST)
     this.DoneFTSS( params['sprint_id'],this.DONE)
    });
    

  }


  getSprint(sprint_id:any){
this.sprintservice.getSprintByid(sprint_id).subscribe(
  (data)=>{
    this.currentSprint=data;
    console.log("currentSprint = ",this.currentSprint); },
    (error)=>{console.log("currentSprint ERROR +",error)})

  }

  update(project_id:any){
    this.sprintservice.updateSprint(this.project_id=this.project_id,this.sprint_id=this.currentSprint.sprint_id,this.title=this.currentSprint.title,this.description=this.currentSprint.description).subscribe(
      (data)=>{console.log("SPRINT UPDATED SECCESFULY = ",data)
      window.location.reload},
      (error)=>{console.log("SPRINT UPDATE ERROR =",error)}
    )
  }

  delete(sprint_id:any){
    console.log("DELETE ACTION")
    this.sprintservice.deleteSprint(this.currentSprint.sprint_id)
    .subscribe(
      (data)=>{console.log("DELETE SPRINT OK =",data)
      this.router.navigate([`/Projectdetails/${this.project_id}`]);
      
      ;},
      (error)=>{console.log("DELETE SPRINT ERORR =",error)}
    )
  }

  TodoFTSS(sprint_id:any,TODO:string){
this.status.FTSS(this.sprint_id,this.TODO).subscribe(
  (next)=>{
    
    this.TodoTasks=next;
  console.log("TodoTasks =>",this.TodoTasks);
},
  (error)=>{console.log("TodoTasks ERROR =>",error)}
)
  }

  DoingFTSS(sprint_id:any,DOING:string){
    this.status.FTSS(this.sprint_id,this.DOING).subscribe(
      (next)=>{
      
        this.DoingTasks=next;
      console.log("DoingTasks =>",this.DoingTasks);
    },
      (error)=>{console.log("DoingTasks ERROR =>",error)}
    )
      }


      TestFTSS(sprint_id:any,TEST:string){
        this.status.FTSS(this.sprint_id,this.TEST).subscribe(
          (next)=>{
          
            this.TestTasks=next;
          console.log("TestTasks =>",this.TestTasks);
        },
          (error)=>{console.log("TestTasks ERROR =>",error)}
        )
          }

          DoneFTSS(sprint_id:any,DONE:string){
            this.status.FTSS(this.sprint_id,this.DONE).subscribe(
              (next)=>{
              
                this.DoneTasks=next;
              console.log("DoneTasks =>",this.DoneTasks);
            },
              (error)=>{console.log("DoneTasks ERROR =>",error)}
            )
              }

  CreateTask(){
    this.status.createTask(this.sprint_id,this.Ttitle).subscribe(
      (next)=>{
      location.reload();
    },
    (error)=>{console.log("TASK NOT CREATED",error)}
    )


  }     
  
 


}