import { Component, OnInit } from '@angular/core';
import { UserService } from '../services/user.service';
import { TokenStorageService } from '../services/token-storage.service';
import { Router } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { User } from '../models/User';

@Component({
  selector: 'app-bar',
  templateUrl: './bar.component.html',
  styleUrls: ['./bar.component.css']
})
export class BarComponent implements OnInit {
  
   User: any;
   role:any;
  Username!:string;
  id:any;
  isAdmin=false;
  isUser=false;
  showMe=false;
  project_id!:any;
  
  constructor(private userService: UserService ,  private router: Router, private token: TokenStorageService , private http: HttpClient) { }

  ngOnInit(): void {

    this.project_id=sessionStorage.getItem("project_id");
    console.log("BAR PROJECT ID => ",this.project_id);
    this.User = this.token.getUser();

        this.role= this.token.getUser().roles;
        this.Username=this.token.getUser().username;
        this.id=this.token.getUser().id
     
  
      if ((this.role=["ROLE_ADMIN"]) &&( this.id != null)){
        
        this.isAdmin=true;
        this.showMe=true;
      }
      else if ((this.role=["ROLE_USER"] ) &&( this.id != null)){
        
        this.isUser=true;
        this.showMe=true;
      
    }
    // else { this.showMe=false;this.router.navigate(['/Auth']); }

}}
