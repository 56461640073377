<div class="container" style="align-self: center;">
    <div class="main-body">
       
           
            <div class="col-lg-8">
                <div class="card">
                    <div class="card-body">

                        <div class="row mb-3">
                            <div class="col-sm-3"  style="color: aqua">
                                <h6 class="mb-0" >ID</h6>
                            </div>
                            <div class="col-sm-9 text-secondary">
                                <input type="text" readonly class="form-control" value="001">
                            </div>
                        </div>

                        <div class="row mb-3">
                            <div class="col-sm-3"  style="color: aqua">
                                <h6 class="mb-0" >Username</h6>
                            </div>
                            <div class="col-sm-9 text-secondary">
                                <input type="text" class="form-control" value="John Doe">
                            </div>
                        </div>
                        <div class="row mb-3">
                            <div class="col-sm-3" style="color: aqua">
                                <h6 class="mb-0">Email</h6>
                            </div>
                            <div class="col-sm-9 text-secondary">
                                <input type="text" class="form-control" value="john@example.com">
                            </div>
                        </div>
                        <div class="row mb-3">
                            <div class="col-sm-3" style="color: aqua">
                                <h6 class="mb-0">Phone</h6></div>
                                <div class="col-sm-9 text-secondary">
                                    <input type="text" class="form-control" value="000000000">
                                </div>
                       <hr>
                        <div class="row">
                            <div class="col-sm-3"></div>
                            <div class="col-sm-9 text-secondary">
                                <input type="button" class="btn btn-primary px-4" value="Save Changes">
                               
                            </div>
                        </div>
                    </div>
                </div>
               
            </div>
        </div>
    </div>
