import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import {CdkDrag, CdkDropList, CdkDropListGroup, DragDropModule} from '@angular/cdk/drag-drop';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { AuthComponent } from './auth/auth.component';
import { AdminDashComponent } from './dash/admin-dash.component';
import { FormsModule } from '@angular/forms';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { AuthInterceptor } from './helpers/auth.interceptor';
import { ProfileComponent } from './profile/profile.component';
import { TestCrudComponent } from './test-crud/test-crud.component';
import { FooterComponent } from './footer/footer.component';
import { BarComponent } from './bar/bar.component';
import { ProjectsComponent } from './projects/projects.component';
import { NotFoundComponent } from './not-found/not-found.component';
import { ProjectDetailsComponent } from './project-details/project-details.component';
import { DatePipe, NgFor } from '@angular/common';
import { MembersComponent } from './members/members.component';
import { SprintComponent } from './sprint/sprint.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { AddMemberINComponent } from './add-member-in/add-member-in.component';
import { ResetPasswordComponent } from './reset-password/reset-password.component';
import {CdkTableModule} from '@angular/cdk/table';
import { TaskDetailsComponent } from './task-details/task-details.component';










@NgModule({
  declarations: [
    AppComponent,
    AuthComponent,
    AdminDashComponent,
    ProfileComponent,
    TestCrudComponent,
    FooterComponent,
    BarComponent,
    ProjectsComponent,
    NotFoundComponent,
    ProjectDetailsComponent,
    MembersComponent,
    SprintComponent,
    AddMemberINComponent,
    ResetPasswordComponent,
    TaskDetailsComponent,
   
  
    
  ],
  imports: [
    FormsModule ,
    BrowserModule,
    AppRoutingModule,
    HttpClientModule,
    BrowserAnimationsModule,
   DragDropModule,
   CdkTableModule,
   
      
  ],
  exports:[
    BarComponent,
    FooterComponent
  ],
  
  providers: [{provide:HTTP_INTERCEPTORS,useClass:AuthInterceptor,multi:true},DatePipe],
  bootstrap: [AppComponent]
})
export class AppModule { }
