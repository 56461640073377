
import { Component, OnInit } from '@angular/core';
import { UserService } from '../services/user.service';
import { TokenStorageService } from '../services/token-storage.service';
import { Router } from '@angular/router';
import { Project } from '../models/Project';
import { User } from '../models/User';
import { ProjectService } from '../services/project.service';
import { SprintService } from '../services/sprint.service';
import { first } from 'rxjs';
import { ActivatedRoute, ActivatedRouteSnapshot } from '@angular/router';




@Component({
  selector: 'app-project-details',
  templateUrl: './project-details.component.html',
  styleUrls: ['./project-details.component.css']
})
export class ProjectDetailsComponent implements OnInit {
  isAdmin=false;
  currentProject :any;
   project_id!: any; 
  title!: string;
  description!: string;
  sprint!:[];
  creator_id!: string;


  p_id:any;
 project!:any;
 projectlist!:any;
 user!: User
 id!:any;
  role:any;
  username:any

  currentSprint!:any;
  Stitle!:string;
Sdescription!: string;
sprintList!:any;
sprint_id!:any;

isDeleted=false;
isUpdated=false;

  constructor(private sprintservice : SprintService,private serviceProjet: ProjectService , private route: Router, public router: ActivatedRoute, private token: TokenStorageService ) { }

  ngOnInit(): void {
   
    this.user = this.token.getUser();
    this.username=this.token.getUser().username;
    this.role= this.token.getUser().roles;
    this.id=this.token.getUser().id
    if ((this.role=["ROLE_ADMIN"]) &&( this.id != null)){
      this.isAdmin=true;}
        // GET ALL PROJECT
      this.serviceProjet.getAll().pipe(first()).subscribe(
        (data)=>{
        
        this.projectlist=data;
       console.log("PROJECT LIST=",this.projectlist)
       
      },(error)=>{console.log(error)})
     
     

  
    const  project_id=this.router.params.subscribe(params => {
      sessionStorage.removeItem("project_id")
      sessionStorage.setItem("project_id",JSON.stringify(params['project_id']))
      console.log("project detail project_id",this.project_id)
           this.getProject(params['project_id']);
          this.getSprintList(params['project_id'])});
         
          
                   }



  
  getProject(project_id:any) {
    this.serviceProjet.get(project_id)
      .subscribe(
        (data) => {
          this.currentProject = data;
   
        },
        error => {
          console.log(error);
        });
          }
  // getSprint(sprint_id:any){
    // this.sprintservice.getSprintByid(sprint_id).subscribe(
      // (data)=>{ this.currentSprint=data},
      // (error)=>{console.log("GET SPRINT BY ID =",error)}
    // )
  // }        

  getSprintList(project_id:any){
    this.serviceProjet.getAllsprints(project_id)
      .subscribe(
        (data)=>{this.sprintList=data
        console.log("SPRINT LIST =",this.sprintList)}
      )
    }
          

  update(project_id:any){ 
      console.log("UPDATE ACTION")
    this.serviceProjet.updatepProject(this.project_id=this.currentProject.project_id,this.title=this.currentProject.title,this.description=this.currentProject.description)
    .subscribe(
     (data)=>{console.log("Updated Project",data) ;
     location.reload();
     
     
       }
     ,(error)=>{console.log(error)
      location.reload();
    }
    )
    };

  delete(project_id:any){
    console.log("DELETE ACTION")
    this.serviceProjet.deleteProject(this.currentProject.project_id)
    .subscribe(
      (data)=>{console.log("DELETE PROJECT OK =",data)
      this.route.navigate([`/Projects`]);},
      (error)=>{console.log("DELETE PROJECT ERROR =",error)}
    )
  };

  Invite(){};

  addSprint(project_id:any){
  this.sprintservice.createSprint(this.Stitle,this.Sdescription,project_id)
  .subscribe(
    (data)=>{console.log("SPRINT CREATED SECCESFULT",data)
    this.route.navigate([`/Projectdetails/${project_id}`]);
    window.location.reload();
    },
    (error)=>{console.log(error)}
    

  )

  

 

  }

 








  }
 

