<div class="container">
<form class="form" (ngSubmit)="resetPassword()">
    <div class="title">Welcome, <br><span></span></div>

    
  <div class="alert alert-warning" *ngIf="userNotExist"> TOKEN EXPIRED OR USER DO NOT EXISIT</div>

    <input type="password" placeholder="New Password" name="password" class="input" autocomplete="off"
    [(ngModel)]="password" [ngModelOptions]="{standalone: true}">
    <input type="password" placeholder="repeat Password" name="Rpassword" class="input" autocomplete="off"
    [(ngModel)]="Rpassword" [ngModelOptions]="{standalone: true}">
    <div class="alert alert-warning" *ngIf="isNotMatch"> PASSWORD DO NO MATCH</div>
    <div class="alert alert-success" *ngIf="passCH"> PASSWORDCHANGED SUCCESSFULLY</div>
    <div class="alert alert-warning" *ngIf="passNotCH"> ERROR WHILE CHANGING PASSWORD </div>
    <button class="button-confirm" >Submit</button>
  </form>
</div>