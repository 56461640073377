import { NgModule } from '@angular/core';
import { ActivatedRoute, RouterModule, Routes } from '@angular/router';
import { AuthComponent } from './auth/auth.component';
import { AdminDashComponent } from './dash/admin-dash.component';
import { ProfileComponent } from './profile/profile.component';
import { TestCrudComponent } from './test-crud/test-crud.component';
import { ProjectsComponent } from './projects/projects.component';
import { NotFoundComponent } from './not-found/not-found.component';
import { ProjectDetailsComponent } from './project-details/project-details.component';
import { MembersComponent } from './members/members.component';
import { SprintComponent } from './sprint/sprint.component';
import { AddMemberINComponent } from './add-member-in/add-member-in.component';
import { ResetPasswordComponent } from './reset-password/reset-password.component';
import { TaskDetailsComponent } from './task-details/task-details.component';

;
const routes: Routes = [

  {path: 'AdminDash',component:AdminDashComponent },
  {path: 'Auth',component:AuthComponent },
  {path: 'Profile',component:ProfileComponent},
  {path: 'test',component:TestCrudComponent},
  {path: 'Projects',component:ProjectsComponent},
  {path: 'Projectdetails/:project_id',component:ProjectDetailsComponent},
  {path: 'members',component:MembersComponent},
  {path: 'sprint/:sprint_id',component:SprintComponent},
  {path: 'newMember',component:AddMemberINComponent},
  {path: 'resetPassword',component:ResetPasswordComponent},
  {path: 'task/:task_id',component:TaskDetailsComponent},
  {path: '',component:AuthComponent},
  
];


@NgModule({
  imports: [RouterModule.forRoot(routes),],
  exports: [RouterModule]
})
export class AppRoutingModule { }
