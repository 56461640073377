import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpErrorResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { catchError, tap, map } from 'rxjs/operators';
import { TokenStorageService } from './token-storage.service';

//const AUTH_API = 'http://localhost:8080/api/auth/';
//const Mail_url = 'http://localhost:8080/';
//const User_API = 'http://localhost:8080/api/v1/user/';

const AUTH_API = 'https://todolist.back.dpc.com.tn/api-docs/api/auth/';
const Mail_url = 'https://todolist.back.dpc.com.tn/api-docs/';
const User_API = 'https://todolist.back.dpc.com.tn/api-docs/api/v1/user/';


const httpOptions = {
  headers: new HttpHeaders({ 'Content-Type': 'application/json' , 'Authorization': `Bearer` ,  }),
  withCredentials: true,
  
  
};

@Injectable({
  providedIn: 'root'
})
export class AuthService {
 
  private handleError(error: HttpErrorResponse) {
    if (error.error instanceof ErrorEvent) {
      console.error('An error occurred:', error.error.message);
    } else {
      console.error(
        `Backend returned code ${error.status}, ` +
        `body was: ${error.error}`);
    }
    return 'Something bad happened; please try again later.';

  }

  constructor(private http: HttpClient) { }

  login(username: string, password: string): Observable<any> {
    return this.http.post<any>(AUTH_API + 'signin',{username,password,},
      httpOptions
    );
  }


  register(  username: string, telephone :number, email: string, password: string, role:any): Observable<any> {
   return this.http.post<any>(AUTH_API + 'signup',{"username": username,"telephone":telephone,"email":email,"password":password,"role":role},
      httpOptions
    );
  }
  mailRegisterMember (recipient:string,project_id:string){
   
return  this.http.post(Mail_url + `sendMail/${project_id}`,{"recipient": recipient});
 }

  registerMember(  username: string, telephone :number, email: string, password: string, role:any,niveau:any,project_id:number,poste:string): Observable<any> {
  
    return this.http.post(AUTH_API + `newMember/${project_id}`,{"username": username,"telephone":telephone,"email":email,"password":password,"role":role,"niveau":niveau,"poste":poste})
}

  logout(): Observable<any> { 
    window.sessionStorage.clear();
    return TokenStorageService.signOut();
  }

  forgotPassword(email:string) :Observable<any>{
    return this.http.post(Mail_url+`FPH/${email}`,{"email":email},{responseType: 'text'})
  }

  findByToken(token:any):Observable<any>{
    return this.http.get<any>(User_API+`findByToken/${token}`)
  }

  newPass(user_id:any,Rpassword:string) :Observable<any>{
return this.http.put(User_API+`newPass/${user_id}`,{"user_id":user_id,"Rpassword":Rpassword},{responseType: 'text'})
  }
}
