<link href="//maxcdn.bootstrapcdn.com/bootstrap/4.0.0/css/bootstrap.min.css" rel="stylesheet" id="bootstrap-css">
<script src="//maxcdn.bootstrapcdn.com/bootstrap/4.0.0/js/bootstrap.min.js"></script>
<script src="//code.jquery.com/jquery-1.11.1.min.js"></script>
<!------ Include the above in your HEAD tag ---------->

<div class="sidenav">
         <div class="login-main-text">
            <h2 style="color: blue;"><b>P L M</b></h2><h2> Welcome to the projects lifecycle manager</h2>
            <p>Login or register from here to access ower services.</p>
         </div>
      </div>
      <div class="main" >
         <div class="col-md-6 col-sm-12">
            <div class="login-form">
               <form name="login" (ngSubmit)="login()">
                  <div class="form-group">
                     <div class="alert alert-success" *ngIf="loginSuccess"> LOGIN SUCCESSFULLY , WELCOME </div>
                    <div class="alert alert-warning" *ngIf="invalidLogin"> INVALID CREDENTIALS</div>
                    <i class="fas fa-user prefix grey-text"></i> &nbsp; 
                     <label>User Name</label>
                     <input type="text" class="form-control" placeholder="User Name" name="username" [(ngModel)]="username" [ngModelOptions]="{standalone: true}">
                  </div>
                  <div class="form-group">
                    <i class="fas fa-lock prefix grey-text"></i> &nbsp;
                     <label>Password</label>
                     <input type="password" class="form-control" placeholder="Password" name="password" [(ngModel)]="password" [ngModelOptions]="{standalone: true}">
                  </div>
                  <button type="submit" class="btn btn-black">Login</button> 

                            <!-- Button trigger modal -->
                  &nbsp; &nbsp;
                    <a href="" class="btn btn-primary" data-toggle="modal" data-target="#modalRegisterForm">Register</a>&nbsp; &nbsp; &nbsp;
                    <a href="" class="btn btn-secondary" data-toggle="modal" data-target="#ForgotPasswordModal"> Forgot password</a>
                    
               </form>
            </div></div>
                          <!-- Register modal -->
                  <div class="modal fade" id="modalRegisterForm" tabindex="-1" role="dialog" aria-labelledby="myModalLabel"
  aria-hidden="true">
  <div class="modal-dialog" role="document">
    <div class="modal-content">
      <div class="modal-header text-center">
        <h4 class="modal-title w-100 font-weight-bold">Sign up</h4>
        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body mx-3">
        <div class="md-form mb-5">
          <form name="signup" (ngSubmit)="signup()">
                    <i class="fas fa-user prefix grey-text"></i> &nbsp;
                    <label data-error="wrong" data-success="right" for="orangeForm-name">Username</label>
                    <input type="text" id="orangeForm-name" class="form-control validate" name="username" [(ngModel)]="username" [ngModelOptions]="{standalone: true}" autocomplete="off">

                    <div class="md-form mb-5">
                    <i class="fas fa-envelope prefix grey-text"></i> &nbsp;
                    <label data-error="wrong" data-success="right" for="orangeForm-name">Email</label>
                    <input type="email" id="orangeForm-email" class="form-control validate" name="email" [(ngModel)]="email" [ngModelOptions]="{standalone: true}" autocomplete="off">
                    </div>

                  <div class="md-form mb-4">
                    <i class="fas fa-lock prefix grey-text"></i> &nbsp;
                    <label data-error="wrong" data-success="right" for="orangeForm-name">Password</label>
                    <input type="password" id="orangeForm-pass" class="form-control validate" name="password" [(ngModel)]="password" [ngModelOptions]="{standalone: true}" autocomplete="off">
                    </div>

                  <div class="md-form mb-5">
                      <i class="fas fa-phone prefix grey-text"></i> &nbsp;
                      <label data-error="wrong" data-success="right" for="orangeForm-name">Telephone</label>
                      <input type="number" id="orangeForm-email" class="form-control validate" name="telephone" [(ngModel)]="telephone" [ngModelOptions]="{standalone: true}" autocomplete="off">
                    </div>

                  
                <div class="modal-footer d-flex justify-content-center">
                  <button class="btn btn-secondary" >Sign up</button>
                
                </div></form></div></div>
              
              
              
              
              </div> 
            </div> </div> 
          
          
          
           <!-- Forgot password modal -->

           <div class="modal fade" id="ForgotPasswordModal" tabindex="-1" role="dialog" aria-labelledby="myModalLabel"
           aria-hidden="true">
           <div class="modal-dialog" role="document">
             <div class="modal-content">
               <div class="modal-header text-center">
                 <h4 class="modal-title w-100 font-weight-bold">Forgot Password</h4>
                
                 <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                   <span aria-hidden="true">&times;</span>
                 </button>
               </div>
               <div class="modal-body mx-3">
                 <div class="md-form mb-5">
                  <p class="py-2"><b>

                    <div class="alert alert-success" *ngIf="mailsent"> REQUEST SENT SUCCESSFULLY </div>
                    <div class="alert alert-warning" *ngIf="mailNotsent"> USER NOT FOUND</div>

                    Enter your email address and we'll send you an email with code to reset your password.
                  </b></p>
                   <form name="signup" (ngSubmit)="forgotPassword()">
                             <i class="fas fa-user prefix grey-text"></i> &nbsp;
                             <label data-error="wrong" data-success="right" for="orangeForm-name">Email</label>
                             <input type="email" id="orangeForm-name" class="form-control validate" name="email" [(ngModel)]="email" [ngModelOptions]="{standalone: true}" autocomplete="off">
                    
                            
                         <div class="modal-footer d-flex justify-content-center">
                           <button class="btn btn-secondary" >Submit</button>
                         
                         </div></form></div></div>
                       
                       
                       
                       
                       </div> 
                     </div> </div> 


          
          </div>
              
            
             




       
          
            
                