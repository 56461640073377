
<form class="form"  (ngSubmit)="Membrsignup()" autocomplete="off" >
    <div class="form-title"><span>sign up to access the </span></div><br>
     <div class="title-2"><span>WORK SPACE</span></div>


     <div class="input-container">
        <input class="input-text" type="text" placeholder="Enter username" name="username" [(ngModel)]="username" [ngModelOptions]="{standalone: true}" autocomplete="off" >
        <span> </span>
      </div>

     <div class="input-container">
       <input class="input-mail" type="email" placeholder="Enter email" name="email" [(ngModel)]="email" [ngModelOptions]="{standalone: true}" autocomplete="off">
       <span> </span>
     </div>

     <section class="bg-stars">
       <span class="star"></span>
       <span class="star"></span>
       <span class="star"></span>
       <span class="star"></span>
     </section>

     <div class="input-container">
       <input class="input-pwd" type="password" placeholder="Enter password" name="password" [(ngModel)]="password" [ngModelOptions]="{standalone: true}" autocomplete="off">
     </div>

     <div class="input-container">
        <input class="input-number" type="number" placeholder="Enter telephone" name="telephone" [(ngModel)]="telephone" [ngModelOptions]="{standalone: true}" autocomplete="off">
      </div>

      <div class="input-container">
        <input class="input-text" type="text" placeholder="Enter niveau" name="niveau" [(ngModel)]="niveau" [ngModelOptions]="{standalone: true}" autocomplete="off">
      </div>
      <div class="input-container">
        <input class="input-text" type="text" placeholder="Enter poste" name="poste" [(ngModel)]="poste" [ngModelOptions]="{standalone: true}" autocomplete="off">
      </div>




     <button type="submit" class="submit">
       <span class="sign-text">Sign up</span>
     </button>

    
      
  </form>
