import { Component, OnInit } from '@angular/core';
import { Router,ActivatedRoute}from '@angular/router';
import { AuthService } from '../services/auth.service';
import { first } from 'rxjs';
import { User } from '../models/User';

@Component({
  selector: 'app-reset-password',
  templateUrl: './reset-password.component.html',
  styleUrls: ['./reset-password.component.css']
})
export class ResetPasswordComponent implements OnInit {
  user !:User
  user_id !: any;
password!:string;
Rpassword!:string;
isNotMatch!:boolean;
  token: any;
  userExist!:boolean;
  userNotExist!:boolean;
  passCH!:boolean;
  passNotCH!:boolean;
  constructor(private route : Router,private router : ActivatedRoute , private authService: AuthService) { }

  ngOnInit(   ): void {
    this.router.queryParams.subscribe(
      params => {
        this.token =params['token']
       
        this.existByToken(this.token);
          
       }
    )
  }

  existByToken(token:any){
     this.authService.findByToken(this.token)
    .pipe(first()).subscribe(
    (next)=>{this.userExist=true;
      this.user = next;
      this.user_id=next.user_id;
      
       

    },
    (error)=>{this.userNotExist=true}
  )}

  resetPassword(){
     if(this.password != this.Rpassword){this.isNotMatch=true;}
     else{
      this.isNotMatch=false;
      this.authService.newPass(this.user_id , this.Rpassword)
      .pipe(first()).subscribe(
        (next)=>{ this.passCH=true;
        this.route.navigate(['/Auth']);},
        (error)=>{this.passNotCH=true}
      )
    
    }
  }




}
