import { Component, OnInit } from '@angular/core';
import { UserService } from '../services/user.service';
import { TokenStorageService } from '../services/token-storage.service';
import { Router } from '@angular/router';
import { Project } from '../models/Project';
import { User } from '../models/User';
import { ProjectService } from '../services/project.service';
import { first } from 'rxjs';



@Component({
  selector: 'app-projects',
  templateUrl: './projects.component.html',
  styleUrls: ['./projects.component.css']
})
export class ProjectsComponent implements OnInit {
  
    project_id!: number
    title!: string;
    description!: string;
    sprint!:[];
    creator_id!: string;
  p_id:any;
 project!:any;
 projectlist!:any;
 user!: User
 id!:any;
  role:any;
  username:any
  isAdmin=false;
  constructor(private service: ProjectService ,  public router: Router, private token: TokenStorageService  ) { }
  p:any;
  ngOnInit(): void {

this.init();
     
}


goToPD(project_id:number){
   this.service.get(project_id).subscribe(
   (data) =>{console.log("GOTOPD",data)
  
  this.router.navigate([`/Projectdetails/${project_id}`]);
  }
   ,(error)=>{console.log(error)}


 )
}

init(){
      this.user = this.token.getUser();
    this.username=this.token.getUser().username;
    this.role= this.token.getUser().roles;
    this.id=this.token.getUser().id
    if ((this.role=["ROLE_ADMIN"]) &&( this.id != null)){
      this.isAdmin=true;}
        // GET ALL
      this.service.getAll().pipe(first()).subscribe(
        (data)=>{
        // console.log("projectData",data)
        this.projectlist=data;
        // console.log("projectData",this.projectlist)

       
      },
        (error)=>{console.log(error)})
}

create(){
  this.service.createProject(this.title,this.description).subscribe(
    (data)=>{
    console.log("PROJECT CREATED SUCCESFULLY => ",data)
   location.reload();
  },
    (error)=>{
      console.log("PROJECT CREATION FAILED => ",error)
      
  }
  )
};
  // delete(project_id:any){
  //   console.log("DELETE ACTION")
  //   this.service.deleteProject(this.p.project_id)
  //   .subscribe(
  //     (data)=>{console.log("delete",data);},
  //     (error)=>{console.log(error)}
  //   )
  // };


}
