import { Injectable } from '@angular/core';



import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { TokenStorageService } from './token-storage.service';
import { HttpParams } from '@angular/common/http';

//const baseUrl = 'http://localhost:8080/api/v1/user';
const baseUrl = 'https://todolist.back.dpc.com.tn/api-docs/api/v1/user';
@Injectable({
  providedIn: 'root'
})
export class MembreService {
 

  constructor(private http: HttpClient) { }

  getAll(project_id:any):Observable<any> {
    const token= localStorage.getItem("auth-token");
    const headers = new HttpHeaders().set("Authorization","Bearer"+token);
    return this.http.get(baseUrl+`/userByProject/${project_id}`)
  }
}
