<table class="table" *ngIf="currentSprint">
    <thead>
      <tr>
       
        <th scope="col" style="text-align: center;">Title</th>
        <th scope="col" style="text-align: center;">Description</th>
        <th scope="col" >Action</th>
      </tr>
    </thead>
    <tbody *ngIf="currentSprint">
      <tr>
     
        <td>
            <input type="text" id="orangeForm-name" class="form-control validate" name="title"  [(ngModel)]="currentSprint.title" [ngModelOptions]="{standalone: true}" >
        </td>
        <td>
            <input type="text" id="orangeForm-name" class="form-control validate" name="description"  [(ngModel)]="currentSprint.description" [ngModelOptions]="{standalone: true}" placeholder="{{currentSprint.description}}">
        </td>
        <td>
            <div  *ngIf="isAdmin">
                <button class="btn btn-warning" type="submit" (click)="update(project_id)">Update</button> &nbsp;
                <button class="btn btn-danger" type="submit" (click)="delete(currentSprint.sprint_id)">Delete</button>
               
              
              </div>
        </td>
      </tr>
    
    </tbody>
  </table>
 

              <!-- KANBAN -->



<div class="container">

<!-- TO DO  -->

<div class="card" >
  <div style="text-align: center;padding-top: 5px"><h2>TO DO</h2></div>
  <div class="tools">
  <div class="card__content"  >

          <!-- NEW TASK -->
          <div class="cards"  >
            <form>
            <div class="card red" >
              <div class="input-group" >
                <div><input type="text" class="input" id="Ttitle" [(ngModel)]="Ttitle" [ngModelOptions]="{standalone: true}" name="Ttitle" placeholder="New task input" autocomplete="off"></div>
                <div><button class="btn btn-primary" (click)="CreateTask()" >Create task</button></div>
            </div>
                <div>
                
              </div>
            </div>
          </form>
          </div>



    <div class="cards" >
      <div class="card red" *ngFor="let t of TodoTasks">
        <a [routerLink]="['/task', t.task_id]" >
          <h1>{{ t.title }}</h1>
        </a>
         
      </div>
    </div>
    

    </div>
</div>
</div>



<!-- DOING -->
<div class="card">
  <div style="text-align: center;padding-top: 5px"><h2>DOING</h2></div>
  <div class="tools">
  <div class="card__content"  >

    <div class="cards" >
      <div class="card yellow" *ngFor="let d of DoingTasks">
        <a [routerLink]="['/task', d.task_id]" >
          <h1>{{ d.title }}</h1>
        </a>
          
      
          
      </div>
    </div>

    </div>
</div>
</div>

<!-- TEST -->
<div class="card">
  <div style="text-align: center;padding-top: 5px"><h2>TEST</h2></div>
  <div class="tools">
  <div class="card__content"  >

    <div class="cards" >
      <div class="card blue" *ngFor="let ts of TestTasks" >
        <a [routerLink]="['/task', ts.task_id]" >
          <h1>{{ts.title }}</h1>
        </a>
        
       
         
      </div>
    </div>

    </div>
</div>
</div>
<!-- DONE -->
<div class="card">
  <div style="text-align: center;padding-top: 5px;"><h2>DONE</h2></div>
  <div class="tools">
  <div class="card__content"  >

    <div class="cards" >
      <div class="card green" *ngFor="let do of DoneTasks">
        <a [routerLink]="['/task', do.task_id]" >
          <h1>{{ do.title }}</h1>
        </a>
          
        
      </div>
    </div>

    </div>
</div>
</div>

</div>

