

          <div class="container text-center"  >
            <div class="row">
              <div class="col" >


               <h1>Project details</h1> <br>

               <div *ngIf="isUpdated">
                  <div class="success"> <h1>Project updated </h1>
                  
                  </div>
              
               </div>

               <div *ngIf="isDeleted">

              
               </div>

               <div class="md-form mb-5" *ngIf="currentProject " style="width:400px;">

                <form name="currentProject" >
                          <div class="md-form mb-5" *ngIf="currentProject">
                                    
                          <div class="md-form mb-5" >
                          <label data-error="wrong" data-success="right" for="orangeForm-name">Project title</label>
                          <input  style="text-align: center;" type="text" id="orangeForm-email" class="form-control validate" name="title" [(ngModel)]="currentProject.title" [ngModelOptions]="{standalone: true}" >
                          </div>
            
                        <div class="md-form mb-5">
                          <label data-error="wrong" data-success="right" for="orangeForm-name">Desciption</label>
                          <textarea style="text-align: center;" rows="9"  id="orangeForm-pass" class="form-control validate" name="description" [(ngModel)]="currentProject.description" [ngModelOptions]="{standalone: true}" ></textarea>
                          </div>
            
                        
                         
                      <div class="modal-footer d-flex justify-content-center" *ngIf="isAdmin">
                        <button   class="btn btn-warning" type="submit" (click)="update(currentProject.project_id)">Update</button>
                        <button class="btn btn-danger" type="submit" (click)="delete(currentProject.project_id)" >Delete</button>
                      
                      </div></div>
                </form>
                    </div>
              </div>
              <div class="col" style="border-left: double; align-items: 50%;" >
                
                    <div style="align-items: center;">
                <h1>Project sprints</h1>
                <div class="container" *ngFor=" let sl of sprintList">
                <div class="card">
                    <span> <a [routerLink]="['/sprint',sl.sprint_id]" >{{sl.title}}</a> </span> 
                    
                </div>  
              
            </div>
                    <hr>
                    <ul class="modal-footer d-flex justify-content-center" style="padding-left:70px ;">
                            
                             <a href="" class="btn btn-warning"  data-toggle="modal" data-target="#modalAddSprint"  > Add sprint </a>
                                   

                            

                                <hr>
                        <!-- <div>


                            <a href="" class="btn btn-primary" data-toggle="modal" data-target="#modalMembers">Members</a>

                        </div> -->
                  </ul>
              </div>
              
            </div>
          </div>


          <!-- MEMBERS MODAL -->
    <div class="modal fade" id="modalMembers" tabindex="-1" role="dialog" aria-labelledby="myModalLabel"
            aria-hidden="true" >
            <div class="modal-dialog" role="document">
                <div class="modal-content">
                        <div class="modal-header text-center">
                            <h4 class="modal-title w-100 font-weight-bold">Members</h4>
                            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                            <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        <div class="modal-body mx-3">
                            <div class="md-form mb-5">
                                <div>
                                    Invite a new member
                                    <form >
                                        <div class="input-group">
                                            <input type="email" class="input" id="Email" name="Email" placeholder="username@gmail.com" autocomplete="off">
                                            <input class="button--submit" value="Invite" type="submit" (ngSubmit)="Invite()">
                                        </div>
                                    </form>
                                </div>

                         
                            </div>
                      
                 </div>      
                      
                      
             </div> 
    </div></div>


    <!-- ADD SPRINT MODAL -->
    <div class="modal fade" id="modalAddSprint" tabindex="-1" role="dialog" aria-labelledby="myModalLabel"
    aria-hidden="true" *ngIf="isAdmin">
    <div class="modal-dialog" role="document">
      <div class="modal-content">
        <div class="modal-header text-center">
          <h4 class="modal-title w-100 font-weight-bold">Add a new sprint</h4>
          <button type="button" class="close" data-dismiss="modal" aria-label="Close">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body mx-3">
          <div class="md-form mb-5">
            <form name="createProject" > 
                      &nbsp;
                      <label data-error="wrong" data-success="right" for="orangeForm-name">Title</label>
                      <input type="text" id="orangeForm-name" class="form-control validate" name="Stitle" [(ngModel)]="Stitle" [ngModelOptions]="{standalone: true}">
  
                      <div class="md-form mb-5">
                      &nbsp;
                      <label data-error="wrong" data-success="right" for="orangeForm-name">description</label>
                      <textarea style="text-align: center;" rows="9" type="text" id="orangeForm-email" class="form-control validate" name="Sdescription" [(ngModel)]="Sdescription" [ngModelOptions]="{standalone: true}"></textarea>
                      </div>
                      <div class="modal-footer d-flex justify-content-center">
                        <button class="btn btn-primary" (click)="addSprint(currentProject.project_id)" >Add</button>
                      
                      </div></form></div> </div></div></div></div>


     