import { Component, OnInit } from '@angular/core';
import { TokenStorageService } from '../services/token-storage.service';
import { HttpClient } from '@angular/common/http';
import { Router } from '@angular/router';
import { first } from 'rxjs';
import { UserCrudService } from '../services/user-crud.service';

@Component({
  selector: 'app-test-crud',
  templateUrl: './test-crud.component.html',
  styleUrls: ['./test-crud.component.css']
})
export class TestCrudComponent implements OnInit {
tokenStorage!: TokenStorageService;
  User:any;
 user_id!:bigint;
 username!: string;
 password!: string;
 email!: string;
 telephone!: number;
 isLoggedIn = false;
role=[""];
  constructor(private router: Router,
    private http: HttpClient,private userCrudService:UserCrudService) { }

  ngOnInit(): void {
    if (this.tokenStorage.getToken()) {
      this.isLoggedIn = true;
      this.role = this.tokenStorage.getUser().roles;
      this.user_id = this.tokenStorage.getUser().user_id;
    }
  }

  GetAllUser(){
    this.userCrudService.getAll()
    .pipe(first()).subscribe(
      (data)=>{console.log(data)}
      ,(error)=>{console.log(error)}
    )
  }

  GetById(id=this.user_id){
    this.userCrudService.get(id)
    .pipe(first()).subscribe(
      (data) =>{console.log(data)}
      ,(error)=>{console.log(error)}
    )
   }

   addUser(){
   this.userCrudService
   .create(this.email,this.password,this.telephone,this.username)
   .pipe(first()).subscribe(
    (data)=>{console.log(data)}
    ,(error)=>{console.log(error)}
   )
   }

   updateUser(){
    {
      
      this.userCrudService
      .update(this.email,this.password,this.telephone,this.username)
      .pipe(first()).subscribe(
       (data)=>{console.log(data)}
       ,(error)=>{console.log(error)}
      )
      }
   }
}
