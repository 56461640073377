<button type="submit" (click)="GetAllUser()"> Get all users</button>
<hr>

<div>
    <form (ngSubmit)="GetById()">
        <label>Id</label>
        <input type="text" class="form-control" placeholder="user_id" name="user_id" [(ngModel)]="user_id" [ngModelOptions]="{standalone: true}">
        <button type="submit"> Get user by id</button>

    </form>
</div>
<hr>
<div>
    <label><b> new user </b></label>
    <form (ngSubmit)="addUser()">
        <label>username</label>
        <input type="text" class="form-control" placeholder="username" name="username" [(ngModel)]="username" [ngModelOptions]="{standalone: true}">
        <label>email</label>
        <input type="text" class="form-control" placeholder="email" name="email" [(ngModel)]="email" [ngModelOptions]="{standalone: true}">
        <label>telephone</label>
        <input type="text" class="form-control" placeholder="telephone" name="telephone" [(ngModel)]="telephone" [ngModelOptions]="{standalone: true}">
        <label>password</label>
        <input type="text" class="form-control" placeholder="password" name="password" [(ngModel)]="password" [ngModelOptions]="{standalone: true}">
        
        
        
        <button type="submit"> new user</button>
        </form>
</div>

<hr><hr><hr>
<div>
    <label><b> Update user </b></label>
    <form (ngSubmit)="updateUser()">
        <label>user_id</label>
        <input type="number" class="form-control" placeholder="user_id" name="user_id" [(ngModel)]="user_id" [ngModelOptions]="{standalone: true}">    
        <label>username</label>
        <input type="text" class="form-control" placeholder="username" name="username" [(ngModel)]="username" [ngModelOptions]="{standalone: true}">
        <label>email</label>
        <input type="text" class="form-control" placeholder="email" name="email" [(ngModel)]="email" [ngModelOptions]="{standalone: true}">
        <label>telephone</label>
        <input type="text" class="form-control" placeholder="telephone" name="telephone" [(ngModel)]="telephone" [ngModelOptions]="{standalone: true}">
        <label>password</label>
        <input type="text" class="form-control" placeholder="password" name="password" [(ngModel)]="password" [ngModelOptions]="{standalone: true}">
        
        
        
        <button type="submit"> update user</button>
        </form>
</div>