import { Component, OnInit } from '@angular/core';
import { UserService } from '../services/user.service';
import { TokenStorageService } from '../services/token-storage.service';
import { Router } from '@angular/router';
import { Project } from '../models/Project';
import { User } from '../models/User';
import { ProjectService } from '../services/project.service';
import { StatusServiceService } from '../services/status-service.service';
import { first } from 'rxjs';
import { ActivatedRoute, ActivatedRouteSnapshot } from '@angular/router';
import { _isNumberValue } from '@angular/cdk/coercion';


import {FormControl, FormsModule, ReactiveFormsModule} from '@angular/forms';
import { MembreService } from '../services/membre.service';



@Component({
  selector: 'app-task-details',
  templateUrl: './task-details.component.html',
  styleUrls: ['./task-details.component.css'],

})
export class TaskDetailsComponent implements OnInit {
  myControl = new FormControl('');
  task_id!:number;
  task:any;
  isAdmin=false;
  currentProject :any;
   project_id!: any; 
  title!: string;
  description!: string;
  etat!:string;
  start_date!:Date;
  deadline!:Date;
  sprint!:[];
  creator_id!: string;

  member_id!:number;
  p_id:any;
 project!:any;
 projectlist!:any;
 user!: User
 id!:any;
  role:any;
  username:any

  currentSprint!:any;
  Stitle!:string;
Sdescription!: string;
sprintList!:any;
sprint_id!:any;
value!:string;
isDeleted=false;
isUpdated=false;
  users: any;
  members:any;

  constructor(private memberS : MembreService,private status : StatusServiceService,private serviceProjet: ProjectService , private route: Router, public router: ActivatedRoute, private token: TokenStorageService) { }

  ngOnInit(): void {

    this.project_id=sessionStorage.getItem("project_id");
    this.projectMembers(this.project_id);

    this.sprint_id=sessionStorage.getItem("sprint_id")
    
    this.user = this.token.getUser();
    this.username=this.token.getUser().username;
    this.role= this.token.getUser().roles;
    this.id=this.token.getUser().id
    if ((this.role=["ROLE_ADMIN"]) &&( this.id != null)){
      this.isAdmin=true;}
       

    const  project_id=this.router.params.subscribe(params => {
     
      this.task_id=parseInt(params['task_id'])})
      this.getTask(this.task_id);
      this.getTaskMembre(this.task_id);
  }


getTask(task_id:number){
  this.status.getById(task_id)
  .subscribe(
    (next)=>{this.task=next;
    console.log("task => ",this.task)},
    (error)=>{console.log("getTask error => ", error)})
}

getTaskMembre(task_id:number){
  this.status.GetMember(task_id).subscribe(
    (next)=>{this.users=next;
    console.log("USERS WORK",this.users)},
    (error)=>{console.log("USERS error => ", error)})
}

onChange(value:string):void{
  this.etat=value;
  console.log(this.etat)
  }
  onChange1(value:string):void{
this.member_id=parseInt(value);
  }


updateTask(task_id:number){
    this.status.UpdateTask(this.task_id,this.title=this.task.title,this.description=this.task.description,this.start_date=this.task.start_date,this.deadline=this.task.deadline,this.etat=this.task.etat)
    .subscribe(
    (next)=>{console.log("Updated task")
    this.route.navigate([`/sprint/${this.sprint_id}`]);},
    (error)=>{console.log("task not updated",error)}
    )


}

deleteTask(task_id:number){
  this.status.DeleteTask(task_id).subscribe(
    (next)=>{console.log("Delatedd task")
    this.route.navigate([`/sprint/${this.sprint_id}`]);
  },
    (error)=>{console.log("task not delated",error)}
  )
    
}

projectMembers(project_id:any){
  this.memberS.getAll(this.project_id).subscribe(
    (data)=>{this.members=data;
    console.log("members = ",this.members)},
    
  )


  }

  update($event:any){

  }

}
