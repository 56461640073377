import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { User } from '../models/User';

//const baseUrl = 'http://localhost:8080/api/v1/user';
const baseUrl = 'https://todolist.back.dpc.com.tn/api-docs/api/v1/user';

@Injectable({
  providedIn: 'root'
})
export class UserCrudService {

  constructor(private http: HttpClient) { }

  // GET ALL USERS
  getAll(): Observable<User[]> {
    return this.http.get<User[]>(`${baseUrl}/findAll`);
  }
  // GET USER BY ID
  get(id: any): Observable<User> {
    return this.http.get<User>(`${baseUrl}/findById/${id}`);
  }

  // NEW USER
  create(email:string,password:string,telephone:number,username:string): Observable<any> {
    return this.http.post<any>(baseUrl+'/add',{"username": username,"telephone":telephone,"email":email,"password":password});
  }

  // UPDATE USER
  update(email:string,password:string,telephone:number,username:string): Observable<any> {
    return this.http.put(baseUrl+'/update/${user_id}',{"username": username,"telephone":telephone,"email":email,"password":password});
  }
  // DELETE USER
  delete(id: any): Observable<any> {
    return this.http.delete(`${baseUrl}/+delete,${id}`);
  }

  // FIND BY EMAIL
  findByEmail(email: any): Observable<User[]> {
    return this.http.get<User[]>(`${baseUrl}/+findByEmail,${email}`);
  }

  // FIND BY USERNAME
  findByUsername(username: any): Observable<User[]> {
    return this.http.get<User[]>(`${baseUrl}/+findByUsername,${username}`);
  }



}
