import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from '../services/auth.service';
import { MembreService } from '../services/membre.service';

@Component({
  selector: 'app-members',
  templateUrl: './members.component.html',
  styleUrls: ['./members.component.css']
})
export class MembersComponent implements OnInit {
  recipient!: string;
  sent!:boolean;
  Notsent!:boolean;
  project_id!:any;
  p_id: any;

  members:any;
  noMembre=false;
 
  constructor( private router: Router,
    private http: HttpClient,private authService: AuthService ,private memberS : MembreService) { }

  ngOnInit(): void {
    this.project_id=sessionStorage.getItem("project_id");

    console.log("membre project ID =>",this.project_id);
    this.projectMembers(this.project_id);
  }

  signupMail(){
    this.authService.mailRegisterMember(this.recipient,this.project_id).subscribe(
      (data) => {
        console.log("DATA SENT OK = ",data)
        this.sent=true;
        window.location.reload();
              },
      (error) => {
        this.Notsent=true;
        console.log("mail sent not ok = ",error)
      }
    );
    }

    projectMembers(project_id:any){
    this.memberS.getAll(this.project_id).subscribe(
      (data)=>{this.members=data;
      console.log("members = ",this.members)},
      (error)=>{this.noMembre=true}
    )


    }

  
  
  
  
  }
