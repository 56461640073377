<div class="wrapper" *ngIf="showMe" >

 

    <!-- Preloader -->
    <div class="preloader flex-column justify-content-center align-items-center">
      <img class="animation__wobble" src="favicon.ico" alt="PLM" height="60" width="60">
    </div>
  
    <!-- Navbar -->
    <nav class="main-header navbar navbar-expand navbar-dark">
      <!-- Left navbar links -->
      <ul class="navbar-nav">
        <li class="nav-item">
          <a class="nav-link" data-widget="pushmenu" href="#" role="button"><i class="fas fa-bars"></i></a>
        </li>
        <!-- <li style="padding-left: 30px"> ID : {{this.id}}</li> <li></li>
        <li style="padding-left: 30px"> Username : {{this.Username}}</li><li></li>
        <li style="padding-left: 30px"> {{this.role}}</li> -->
       
      </ul>
  
      <!-- Right navbar links -->
      <ul class="navbar-nav ml-auto">
        <!-- Navbar Search -->
        <li class="nav-item">
          
          <div class="navbar-search-block">
            <form class="form-inline">
              <div class="input-group input-group-sm">
                <input class="form-control form-control-navbar" type="search" placeholder="Search" aria-label="Search">
                <div class="input-group-append">
                  <button class="btn btn-navbar" type="submit">
                    <i class="fas fa-search"></i>
                  </button>
                  <button class="btn btn-navbar" type="button" data-widget="navbar-search">
                    <i class="fas fa-times"></i>
                  </button>
                </div>
              </div>
            </form>
          </div>
        </li>
  
       
      
        <!-- Notifications Dropdown Menu -->
        <li class="nav-item dropdown">
          <a class="nav-link" data-toggle="dropdown" href="#">
            <i class="far fa-sign-out" aria-hidden="true"></i>
            
          </a>
         
        </li>
        
       
       
      </ul>
      <div></div>
    </nav>
    <!-- /.navbar -->
  
    <!-- Main Sidebar Container -->
    <aside class="main-sidebar sidebar-dark-primary elevation-4">
      <!-- Brand Logo -->
      <a href="/AdminDash" class="brand-link">
        <img src="favicon.ico" alt="AdminLTE Logo" class="brand-image img-circle elevation-3" style="opacity: .8">
        <span class="brand-text font-weight-light">PLM </span>
      </a>
  
      <!-- Sidebar -->
      <div class="sidebar">
        <!-- Sidebar user panel (optional) -->
       
  
        <!-- SidebarSearch Form -->
        
  
        <!-- Sidebar Menu -->
        <nav class="mt-2">
          <ul class="nav nav-pills nav-sidebar flex-column" data-widget="treeview" role="menu" data-accordion="false">
            <!-- Add icons to the links using the .nav-icon class
                 with font-awesome or any other icon font library -->
                 <li class="nav-item">
                  <a href="/Profile" class="nav-link ">
                  <i class="fa-duotone fa-user"></i>
                  <p>
                    {{User.username}}
                    </p>
                </a>
              </li>
           
           
           
           
                 <li class="nav-item">
                <a href="/AdminDash" class="nav-link " active>
                <i class="nav-icon fas fa-tachometer-alt"></i>
                <p>
                  Dashboard
                  </p>
              </a>
            </li>
            <li class="nav-item">
              <a href="/Projects" class="nav-link">
                <i class="nav-icon fas fa-th"></i>
                <p>
                  Projects
                </p>
              </a>
            </li>
            <li class="nav-item" *ngIf="project_id != null">
              <a href="/members" class="nav-link">
                <i class="nav-icon fas fa-copy"></i>
                <p>
                  Members
                </p>
              </a>
  
            </li>
  </ul>
        </nav>
        
      </div>
      
    </aside>
  
    <!-- Content Wrapper. Contains page content -->
    <div class="content-wrapper">
      <!-- Content Header (Page header) -->
      <div class="content-header">
        <div class="container-fluid">
          <div class="row mb-2">
        
      
      
        <router-outlet>
  
        </router-outlet>
  
          
          </div>
          
          </div>
        </div>
       
      </div>
      
  
  
    <!-- Control Sidebar -->
    <aside class="control-sidebar control-sidebar-dark">
     
    </aside>
    
  
  
    
  </div>
  
