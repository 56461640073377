import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { TokenStorageService } from '../services/token-storage.service';
import { Observable } from 'rxjs';
import { AuthService } from '../services/auth.service';
import { first} from 'rxjs/operators';
import { User } from '../models/User';


@Component({
  selector: 'app-auth-component',
  templateUrl: './auth.component.html',
  styleUrls: ['./auth.component.css']
})
export class AuthComponent implements OnInit {
  tokenStorage: TokenStorageService;
   user!:User
  user_id!:number;
  username!: string;
  password!: string;
  email!: string;
  Rpassword!: string;
  telephone!: number;
  role:any =["admin"];
  sm=false;
  nosm=false;
  invalidLogin = false;
  loginSuccess = false;
  isLoggedIn = false;
  test!:any;
 mailsent!:boolean;
 mailNotsent!:boolean;
  constructor(private router: Router,
    private http: HttpClient,
    private tokenStorageService: TokenStorageService,
    private authService: AuthService) { this.tokenStorage = tokenStorageService;}

  ngOnInit(): void {
    
    if (this.tokenStorage.getToken()) {
      this.isLoggedIn = true;
      this.role = this.tokenStorage.getUser().roles;
      this.user_id = this.tokenStorage.getUser().id;
    }
    window.location.reload
  }

  login() {
    this.authService
      .login(this.username, this.password)
      .pipe(first())
      .subscribe(
        (data) => {
          this.user = data;
          this.tokenStorageService.saveToken(data['accessToken']);
          this.tokenStorageService.saveUser(this.user);
            this.loginSuccess = true;
              this.invalidLogin = false;
              this.isLoggedIn = true;
            
              this.router.navigate(['/AdminDash']);
        },
        (error) => {
          this.invalidLogin = true;
          this.loginSuccess = false;
          this.isLoggedIn = false;
          return 'Error login';
        }
      );
  }

  signup() {
    this.authService
      .register(this.username, this.telephone,  this.email, this.password, this.role)
      .pipe(first())
      .subscribe(
        (data) => {
          console.log(data)
          this.sm=true;
          window.location.reload();
                },
        (error) => {
          this.nosm=true;
          console.log('erreur signup',error)
        }
      );
  }

  logout() {
    this.authService.logout;
    this.router.navigate(['/Auth']);
  }

  forgotPassword( ){
    this.authService.forgotPassword(this.email)
    .subscribe(
      (next)=>{console.log(next)
      if(next="User Not Found") {this.mailNotsent=true}
      else{this.mailsent=true}
      },
      (error)=>{console.log(error)
      }
    )
  }


}
